<template>
  <div class="container" v-if="content">
    <header class="jumbotron">
      <h3 >Ingredient {{ content.data.name }}</h3>
    </header>
    <div>
        <p>Unit: {{content.data.unit_name}}</p>
        <p>Unit amount: {{content.data.unit_amount}}</p>
        <p>Unit fixed cost (base): {{content.data.unit_fixed_cost}}</p>
        <p>total providers: {{ content.data.providers.length }}</p>
    </div>

    <div>
      <h3>Providers</h3>
      <table class="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Name</th>
              <th scope="col">Is Base?</th>
              <th scope="col">last cost</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(value, key) in content.data.providers" v-bind:key="key">
              <th scope="row">{{value.id}}</th>
              <td><router-link :to="{ name: 'ingredient-provider', params: { 'ingredientId': content.data.id, 'providerId': value.id }}">{{value.name}}</router-link></td>
              <td scope="row">
                <div class="form-check form-switch">
                  <input @change="setProviderBase($event, value.id)" class="form-check-input" type="checkbox" id="is_base" :checked="value.is_base == 1">
                </div>
              </td>
              <td>{{ value.last_cost }}</td>
            </tr>
          </tbody>
        </table>
    </div>
  </div>
</template>

<script>
import IngredientsService from "../services/ingredients.service";
import ProviderService from "../services/provider.service";
import { useRoute } from 'vue-router'

export default {
  name: "ingredient-view",
  methods:{
    setProviderBase(event, provider_id){
      // console.log(event.target.value);
      // const value = this.$refs.myInput.value;
      // console.log(value);
      this.formData = {
        'ingredient_id': this.ingredient_id,
        'provider_id': provider_id
      };
      ProviderService.setProviderBaseToIngredient(this.formData).then(
          (response) => {
            this.message = response.data.message;
            window.location.reload(); //@TODO change this behavior
          },
          (error) => {
            this.message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
    }
  },
  data() {
    return {
      content: "",
      formData: '',
      ingredient_id: '',
      provider_id: '',
    };
  },
  mounted() {
    if (this.loggedIn) {
      // this.$router.push("/profile");
    }
    const route = useRoute()
    IngredientsService.getIngredient(route.params.ingredientId).then(
      (response) => {
        this.content = response.data;
        this.ingredient_id = this.content.data.id
        // console.log(this.content);
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  },
};

</script>